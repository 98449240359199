/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { EMAIL_REGEX } from "constant";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "providers/Notification";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PasswordField from "components/PasswordField";
import { SSOIcons } from "./SSOIcons";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";
import { useLazyQuery } from "@apollo/client";
import { CHECK_USER_PROVIDER } from "queries";
import { checkProviderResponse } from "types";
import useMediaQuery from "@mui/material/useMediaQuery";

interface LoginFormValues {
  username: string;
  password: string;
}

function LoginForm() {
  const notify = useNotifications();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [rememberEmail, setRememberEmail] = useState(
    Boolean(localStorage.getItem("savedEmail"))
  );

  const { register, handleSubmit, formState, watch, control } =
    useForm<LoginFormValues>({
      defaultValues: {
        username: localStorage.getItem("savedEmail") || "",
        password: "",
      },
      mode: "all",
    });

  const usernameValue = watch("username");

  useEffect(() => {
    if (rememberEmail) {
      localStorage.setItem("savedEmail", usernameValue);
    } else {
      localStorage.removeItem("savedEmail");
    }
  }, [rememberEmail]);

  const handleRememberCheckbox = () => {
    setRememberEmail(!rememberEmail);
  };

  const onSubmitCognito = async ({ username, password }: LoginFormValues) => {
    try {
      const data = await Auth.signIn({ username, password });
      insertApiResponseDataDog("authSignInApi(success)", data, "info")
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      if (token) {
        window.localStorage.setItem("token", token);
        navigate("/home", { replace: true });
      } else {
        notify.error("Login Failed");
      }
    } catch (error: any) {
      insertApiResponseDataDog("authSignInApi(error)", error, "error");
      insertApiResponseDataDogError(error, "authSignInApi(error)")
      if (error?.name === "UserNotConfirmedException") {
        navigate('/verifyEmail', { state: { email: username, password: password } });
      } else {

        notify.error(error.message);
      }
    }
  };

  const [CheckProvider, { loading: providerLoading }] = useLazyQuery<checkProviderResponse>(CHECK_USER_PROVIDER, {
    onCompleted(data) {
      if (data.findProviderByEmail.provider === 'Mnp') {
        handleSubmit(onSubmitCognito)()
      } else {
        const provider = data.findProviderByEmail.provider === 'SignInWithApple' ? 'Apple' : data.findProviderByEmail.provider
        notify.error(`You registered myNILPay with  ${provider}. Please login with your ${provider} account.`)
      }
      insertApiResponseDataDog("CheckUserProviderApi(success)", data, "info");
    },
    onError(error) {
      insertApiResponseDataDogError(error, "CheckUserProviderApi(error)");
      insertApiResponseDataDog("CheckUserProviderApi(error)", error, "error");
      notify.error(error.message)
    },
  });

  const onSubmit = ({ username }: LoginFormValues) => {
    CheckProvider({
      variables: {
        email: username
      }
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>

        <Stack direction="column" alignItems="flex-start" spacing={1} style={{ marginTop: -42 }}>
          <Typography marginBottom={2} fontWeight="light">
            {"Please sign in with your information"}
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            data-testid="username"
            label="Email"
            placeholder="Enter email"
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            fullWidth
            error={Boolean(formState.isDirty && formState.errors.username)}
            disabled={formState.isSubmitting}
            helperText={
              (formState.isDirty && formState.errors.username?.message) || " "
            }
            {...register("username", {
              required: "Email is required",
              pattern: {
                value: EMAIL_REGEX,
                message: "Invalid email address",
              },
            })}
          />
          <Stack width="100%">
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <PasswordField
                  inputProps={{ "data-testid": "password" }}
                  error={
                    formState.isDirty && Boolean(formState.errors.password)
                  }
                  disabled={formState.isSubmitting}
                  helperText={
                    (formState.isDirty &&
                      formState.errors.password?.type === "required" &&
                      "Password is required") ||
                    " "
                  }
                  {...field}
                  ref={null}
                />
              )}
            />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
              width="100%"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid="remember-checkbox"
                    onChange={handleRememberCheckbox}
                    size="small"
                    checked={rememberEmail}
                  />
                }
                label={<Typography fontSize={14}>Remember Me</Typography>}
              />
              <Button
                data-testid="forgot-button"
                variant="text"
                onClick={() => navigate("/forgotPassword")}
              >
                <Typography fontSize={14}>Forgot Password</Typography>
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <SSOIcons />
        <Stack
          marginTop={isMobile ? "10px" : "100px"}
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0}
          width="100%"
        >
          <Box marginBottom="24px" display="flex" alignItems="center">
            <Typography>Don&apos;t have an account?</Typography>
            <Button variant="text" onClick={() => navigate("/register")}>
              <Typography fontSize={16} fontWeight={500}>
                Register
              </Typography>
            </Button>
          </Box>
        </Stack>
        <Box>
          <Button
            startIcon={
              formState.isSubmitting || providerLoading ? <CircularProgress size={20} /> : null
            }
            data-testid="submit-button"
            type="submit"
            variant="contained"
            disabled={!formState.isValid || formState.isSubmitting || providerLoading}
            fullWidth
          >
            Log In
          </Button>
        </Box>

      </form>
      {/*
      <Stack
        marginTop="10px"
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        width="100%"
      >
        <Box marginBottom="0px" display="flex" alignItems="center">
          <Typography>Trouble logging in?</Typography>
          <Button variant="text" onClick={() => window.zE.activate()}>
            <Typography fontSize={16} fontWeight={500}>
              Chat with us
            </Typography>
          </Button>
        </Box>


      </Stack>
      */}
    </>
  );
}

export default LoginForm;
