import { useMutation } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useNotifications } from "providers/Notification";
import { TRANSACTION_INVOICE } from "queries";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { InvoiceArgs, InvoiceResponse } from "types";
import { insertApiResponseDataDog, insertApiResponseDataDogError } from "utils";

const Invoice = () => {
    const EMAIL = 'support@mynilpay.com';
    const SUPPORT_LINK = 'https://www.mynilpay.org/support'
    const notify = useNotifications();
    const params = useParams();
    const transactionId = params.id
    const [retrieveReceipt, { data, loading }] =
        useMutation<InvoiceResponse, InvoiceArgs>(TRANSACTION_INVOICE, {
            onError: (error) => {
                insertApiResponseDataDog("retrieveReceiptApi(error)", error, "error")
                notify.error("Something went wrong. Try again later");
                insertApiResponseDataDogError(error, "retrieveReceiptApi(error)");
            },
            onCompleted(data) {
                insertApiResponseDataDog("retrieveReceiptApi(success)", data, "info")

            },
        });
    useEffect(() => {
        if (transactionId) {
            retrieveReceipt({ variables: { transactionId } });
        }
    }, [transactionId]);
    if (loading) { return <CircularProgress />; }
    return (
        <>
            {data ?
                <Box className="payment-receipt">
                    <Box textAlign={"center"}>
                        <h2>Receipt From myNILpay, Inc.</h2>
                    </Box>
                    <Box className="payment-receipt__details" sx={{ mt: 3 }} justifyContent={"space-between"} display={"flex"} textAlign={"center"}>
                        <Box>
                            <strong>Amount Paid:</strong>
                            <small>${data?.retrieveReceiptCko.amountWithoutTax.toFixed(2)}</small>
                        </Box>
                        <Box>
                            <strong>Date Paid:</strong>
                            <small>{data?.retrieveReceiptCko.transactionApprovalDate}</small>
                        </Box>
                        <Box>
                            <strong>Payment Method:</strong>
                            <small>Card-{data?.retrieveReceiptCko.cardLastFourDigits}</small>
                        </Box>
                    </Box>
                    <Box className="payment-receipt__summary" sx={{ mt: 3 }}>
                        <strong>Summary</strong>

                    </Box>
                    <Box sx={{ mt: 2 }} className="payment-receipt__amount">
                        <Box justifyContent={"space-between"} display={"flex"}>
                            <span>Payment x {data?.retrieveReceiptCko.athleteCount}</span>
                            <span>${data?.retrieveReceiptCko.amountWithoutTax.toFixed(2)}</span>
                        </Box>
                        <Box sx={{ mt: 1 }} justifyContent={"space-between"} display={"flex"}>
                            <span>State Sales Tax</span>
                            <span>${data?.retrieveReceiptCko.tax.toFixed(2)}</span>
                        </Box>
                        <Divider sx={{ mt: 1 }} />
                        <Box sx={{ mt: 1 }} justifyContent={"space-between"} display={"flex"}>
                            {data?.retrieveReceiptCko.cardTransactionType === "SUCCESSFUL" ?
                                <strong>Amount Charged</strong> : <strong>Refund Amount</strong>}
                            <strong>${data?.retrieveReceiptCko.amountWithTax.toFixed(2)}</strong>
                        </Box>
                    </Box>
                    <Box className="payment-receipt__footer" textAlign={"center"}>
                        <p>If you have any questions, visit our support site at <a target={"_new"} href={SUPPORT_LINK}>{SUPPORT_LINK}</a></p>
                        <p>Contact us at <a href={`mailto:${EMAIL}?subject=General Support`}>{EMAIL}</a> Or call at <a href="tel:+1 510-369-5137">+1 510-369-5137</a></p>
                    </Box>
                </Box>
                : <p>Something went wrong. Try again later</p>}
        </>
    )
}
export default Invoice;